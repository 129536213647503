import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  loggedIn:boolean;
  username:string;

  constructor(
    private _http: HttpClient
  ) { 
    this.checkLogin()

  }

  public checkLogin() {
    return this._http.get(environment.baseUrl + '/admin', {withCredentials:true}).toPromise()
    .then(
      (res : any) => {
        this.loggedIn = true;
        this.username = res.username;
        console.log("checkLogin", true)
        return true
      },
      err => {
        this.loggedIn = false;
        console.log("checkLogin", false)
        
        return false;
      }
    )
  }
 
  public login(email, rememberMe) {
    return this._http.post(environment.baseUrl + '/admin/login', {
      email: email,
      rememberMe: rememberMe
    }).toPromise();
  }

  public verify(token) {
    return this._http.post(environment.baseUrl + '/admin/verify', {
      token: token
    }, {withCredentials:true}).toPromise();
  }

  public listVenues(options?:{noimage?:Boolean, verified?:boolean, wikidata?:boolean, sponsored?:boolean}) {
    let url = environment.baseUrl + '/admin/directory';
    
    if(options) {
      url += "?";
      if(options.noimage) {
        url += "noimage=true&"
      }
      if(options.verified) {
        url += "verified=true&"
      }
      if(options.wikidata) {
        url += "wikidata=true&"
      }
      if(options.sponsored) {
        url += "sponsored=true&"
      }
    }

    return this._http.get(url, {withCredentials:true}).toPromise();
  }

  public getVenuesWithoutImages() {
    let url = environment.baseUrl + '/admin/images/noimage';
    
    return this._http.get(url, {withCredentials:true}).toPromise();
  }

  public getVenue(venueId:string) {
    return this._http.get(environment.baseUrl + '/admin/directory/' + venueId, {withCredentials:true}).toPromise();
  }

  public getVenueEmail(venueId:string) {
    return this._http.get(environment.baseUrl + '/admin/directory/' + venueId + '/email', {withCredentials:true}).toPromise();
  }

  public verifyVenue(venueId:string, email:string, status?:'positive'|'negative'|'unknown') {
    let body :any = {
      email:email
    };
    body.status = status ? status : 'unknown';

    return this._http.post(environment.baseUrl + '/admin/directory/' + venueId + '/verify', body, {withCredentials:true}).toPromise();
  }

  public saveOnlineImage(venueId:string, url:string, attr?:string, attr_url?:string) {
    let body = {
      url:url
    }
    if(attr) {
      body['attr'] = attr
    }
    if(attr_url) {
      body['attr_url'] = attr_url
    }
    return this._http.post(environment.baseUrl + '/admin/images/image/' + venueId, body, {withCredentials:true}).toPromise()

  }
}
