import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email:string;
  rememberMe:boolean;

  sent:boolean;
  error:boolean;

  constructor(
    private _data : DataService,
    private route : ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit() {
    let token = this.route.snapshot.queryParamMap.get('token');
    console.log(this.route.snapshot.queryParamMap.get('token'))
    if(token) {
      this.verify(token);
    }
  }

  login() {
    this._data.login(this.email, this.rememberMe)
    .then(
      res => {
        this.sent = true;
      },
      err => {
        this.error = true;
      }
    )
  }

  verify(token:string) {
    this._data.verify(token)
    .then(
      res => {
        this.router.navigate(['/dashboard']);
      },
       err => {
         console.log("error", err)
         this.error = true;
       }
    )
  }

}
