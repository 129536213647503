import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'type'
})
export class TypePipe implements PipeTransform {

  transform(value: any): any {
    if(value.address) {
      return Object.keys(value.address)[0];
    }
  }

}
