<div class="clr-row">
    <div class="clr-col">
        <h3>{{venue|name}} | Dashboard</h3>
    </div>
</div>
<div class="clr-row clr-justify-content-center">
    <div class="clr-col-md-6">
        <div>
            <h4>Listing</h4>
            <table class="table table-vertical">
                <tr>
                    <th>Status</th>
                    <td>
                        <app-status [place]="venue"></app-status>
                    </td>
                </tr>
                <tr *ngIf="venue.verify">
                    <th>Verified Date</th>
                    <td>
                        {{venue.verify.date|date}}
                    </td>
                </tr>
                <tr *ngIf="venue.btd_ratings">
                    <th>Rating</th>
                    <td>
                        {{venue.btd_ratings.percent|percent}} <small>({{venue.btd_ratings.up+venue.btd_ratings.down}} votes)</small>
                    </td>
                </tr>
            </table>
        </div>
        <div>
            <h4>Verify Listing</h4>
            <div class="card">
                <div *ngIf="venue.verify"  class="card-block">
                    Verified on {{venue.verify.date|date}}
                </div>
                <div class="card-block">
                    <div class="alert alert-success" *ngIf="verify_submitted" role="alert">
                        <div class="alert-items">
                            <div class="alert-item static">
                                <div class="alert-icon-wrapper">
                                    <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                                </div>
                                <span class="alert-text">Verification submitted!</span>
                            </div>
                        </div>
                    </div>
                    <form (submit)="verifyVenue()" clrForm>
                        <clr-input-container>
                            <label>Email</label>
                            <input clrInput placeholder="Email" name="email" [(ngModel)]="verify_email" />
                          </clr-input-container>
                          <clr-select-container>
                            <label>Status</label>
                            <select clrSelect name="verify_status" [(ngModel)]="verify_status">
                                <option selected="selected" value="unknown">Unknown</option>
                              <option value="positive">Dog Friendly</option>
                              <option value="negative">No Dogs</option>
                            </select>
                            <clr-control-helper>Dog Friendly - Confirmed as dog friendly.<br />No Dogs - Confirmed as no dogs allowed.<br />Unknown - Email the owner to check.</clr-control-helper>
                          </clr-select-container>
                          <div>
                              <p>
                                  <button class="btn btn-sm btn-primary" type="submit">Save</button>
                              </p>
                          </div>
                    </form>
                </div>

            </div>
        </div>
        <div>
            <h4>Facilities for Dogs</h4>
            <div class="card">
                <div class="card-block">
                    <form clrForm>
                        <clr-radio-container>
                            <label>Access Level</label>
                            <clr-radio-wrapper>
                                <input clrRadio type='radio' id="access1" name='access' value='1' />
                                <label> 1 - Outside Only</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input clrRadio type='radio' id="access2" name='access' value='2' />
                                <label> 2 - Specific Areas</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input clrRadio type='radio' id="access3" name='access' value='3' />
                                <label> 3 - Access All Areas</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>

                        <clr-toggle-container>
                            <label>Facilities</label>
                            <clr-toggle-wrapper>
                                <input clrToggle type='checkbox' id="water" name='water' value='true' />
                                    <label>Water Bowls</label>
                            </clr-toggle-wrapper>
                            <clr-toggle-wrapper>
                                <input clrToggle type='checkbox' id="treats" name='treats' value='true' />
                                    <label>Treats</label>
                            </clr-toggle-wrapper>
                            <clr-toggle-wrapper>
                                <input clrToggle type='checkbox' id="beds" name='beds' value='true' />
                                    <label>Dog beds / Blankets</label>
                            </clr-toggle-wrapper>
                        </clr-toggle-container>

                        <clr-textarea-container>
                            <label>Comments / Rules</label>
                            <textarea clrTextarea rows="6" id="comments" name="comments"></textarea>
                          </clr-textarea-container>


                        <p>
                            <button class="btn btn-sm btn-primary" type="submit">Save</button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-md-6">
        <div>
            <h4>Image</h4>
            <clr-tabs>
                <clr-tab *ngIf="venue?.btd_image">
                    <button clrTabLink id="link1">Live</button>
                    <clr-tab-content id="content1" *clrIfActive="goToLiveImg">
                        <div><img [src]="venue.btd_image.src" style="height: 250px;" /></div>
                        <div>{{venue.btd_image.attr}}</div>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab *ngIf="wikidataimg">
                    <button clrTabLink>Wikidata</button>
                    <clr-tab-content >
                        <button class="btn btn-sm btn-link" (click)="saveAsLive(wikidataimg, 'Image: Wikidata')">Save as Live</button>
                        <div><img [src]="wikidataimg" style="height: 250px;" /></div>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink><clr-icon shape="upload-cloud"></clr-icon></button>
                    <clr-tab-content>
                        <p>The below image will be uploaded to the BTD servers and set as the live image.</p>
                        <form clrForm>
                            <input clrInput placeholder="URL" name="newimg" [(ngModel)]="newimg.url" />
                            <input clrInput placeholder="Attribution" name="attr" [(ngModel)]="newimg.attribution" />
                            <button (click)="saveAsLive(newimg.url, newimg.attribution)" class="btn btn-sm btn-primary">Upload</button>
                        </form>
                    </clr-tab-content>
                </clr-tab>
            </clr-tabs>
        </div>
        <div>
            <h4>Business Details</h4>
            <table class="table table-vertical">
                <tr>
                    <th>Name</th>
                    <td>{{venue|name}}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{{venue|type|uppercase}}</td>
                </tr>
                <tr>
                    <th>Address</th>
                    <td>
                        <div>{{venue.address?.house_number}} {{venue.address?.road}}</div>
                        <div>{{venue.address?.suburb}}</div>
                        <div>{{venue.address?.city}}</div>
                        <div>{{venue.address?.county}}</div>
                        <div>{{venue.address?.postcode}}</div>
                    </td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{{venue.btd_email}}</td>
                </tr>
                <tr>
                    <th>Phone</th>
                    <td>{{venue.extratags?.phone}}</td>
                </tr>
                <tr>
                    <th>Website</th>
                    <td>{{venue.extratags?.website}}</td>
                </tr>
                <tr>
                    <th>View on Map</th>
                    <td><a target="_blank" href="{{venue._id|osmLink}}">MAP</a></td>
                </tr>
                <tr *ngIf="venue.extratags?.wikidata">
                    <th>Wikidata</th>
                    <td><a target="_blank" href="https://www.wikidata.org/wiki/{{venue.extratags.wikidata}}">Open</a></td>
                </tr>
            </table>
        </div>
        <div>
            <h4>Media & Printouts</h4>
            <div class="card">
                <div class="card-block">
                    <div>Free flyers and images to promote your dog friendly status.</div>
                </div>
            </div>
        </div>

    </div>
</div>