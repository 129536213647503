import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.css']
})
export class VenueListComponent implements OnInit {
  venues:any[];

  noimage:boolean;
  verified:boolean;
  wikidata:boolean;
  sponsored:boolean;

  constructor(
    private _data:DataService,
    private route: ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {
    this.route.queryParamMap.pipe()
    .subscribe(params => {
      this.noimage = params.has("noimage");
      this.verified = params.has("verified");
      this.wikidata = params.has("wikidata");
      this.sponsored = params.has("sponsored");
      this.getVenues();
    })
    this.getVenues();
  }

  updateRoute() {
    let query:any = {};
    this.noimage ? query['noimage'] = true : null;
    this.verified ? query['verified'] = true : null;
    this.wikidata ? query['wikidata'] = true : null;
    this.sponsored ? query['sponsored'] = true : null;
    this.router.navigate(['/dashboard/directory'], {queryParams:query})
  }

  getVenues() {
    let options = {};
    if(this.noimage) {
      options["noimage"] = true;
    }

    if(this.verified) {
      options["verified"] = true;
    }

    if(this.wikidata) {
      options["wikidata"] = true;
    }

    if(this.sponsored) {
      options["sponsored"] = true;
    }

    this._data.listVenues(options)
    .then(
      (res:any[]) => {
        console.log(res)
        this.venues = res;
      },
      err => {
        console.log(err)
      }
    )
  }

}
