import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'osmLink'
})
export class OsmLinkPipe implements PipeTransform {

  transform(value: string): unknown {
    let idSplit = value.substring(7).split("-")
    return `https://www.openstreetmap.org/${idSplit[1]}/${idSplit[0]}`;
  }

}
