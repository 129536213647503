import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  loggedIn:boolean;

  constructor(
    private data:DataService,
    private _router : Router
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.loggedIn) {
        return this.data.checkLogin()
        .then(
          res => {
            if(!res) {
              this._router.navigate(['/login'])
            } else {
              this.loggedIn = true;
            }
            return res;
          }
        )
      } else {
        return true;
      }

  }
  
}
