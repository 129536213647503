import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { VenueDetailsComponent } from './routes/venue-details/venue-details.component';
import { LoginComponent } from './routes/login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { VenueListComponent } from './routes/venue-list/venue-list.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  {path:'', redirectTo:'dashboard', pathMatch:'prefix'},
  {path:'login', component:LoginComponent},
  {path: 'dashboard', component: LayoutComponent, canActivate:[AuthGuard], children:[
    {path:'', component:DashboardComponent},
    {path:'directory', component:VenueListComponent},
    {path:'directory/:id', component:VenueDetailsComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
