<div class="login-wrapper">
    <form *ngIf="!sent" (submit)="login()" class="login">
        <section class="title">
            <h3 class="welcome">Dashboard</h3>
            BringThe.Dog
            <h5 class="hint">Simply enter your email address and we'll send you a login link.</h5>
        </section>
        <div class="login-group">
            <clr-input-container>
                <label class="clr-sr-only">User</label>
                <input type="text" name="email" clrInput placeholder="Email Address" [(ngModel)]="email"/>
            </clr-input-container>
            <clr-checkbox-wrapper>
                <label>Remember me</label>
                <input type="checkbox" name="rememberMe" clrCheckbox [(ngModel)]="rememberMe"/>
            </clr-checkbox-wrapper>
            <button type="submit" class="btn btn-primary">Login / Sign Up</button>
        </div>
    </form>
    <form *ngIf="sent" class="login">
        <section class="title">
            <h3 class="welcome">Dashboard</h3>
            BringThe.Dog
            <h5 class="hint">Sent. Please check your inbox.</h5>
        </section>

    </form>
</div>