<div class="main-container">
    <header class="header header-1">
      <div class="branding-pam">
        <a [routerLink]="['/']" class="nav-link nav-icon">
          <img src="assets/icon-rounded.png" height="32px" />
        </a>
      </div>
      <div class="header-nav">
        <a [routerLink]="['/dashboard/directory']" routerLinkActive="active" class="nav-link"><span class="nav-text">Directory</span></a>
      </div>
      <div class="header-actions">
        <a href="javascript://" class="nav-link nav-icon" aria-label="Log Out">
            <clr-icon shape="logout"></clr-icon>
        </a>
    </div>
    </header>
    <div class="content-container">
      <div class="content-area">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>