import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as MD5 from 'md5';
import * as op from 'object-path';

@Injectable({
  providedIn: 'root'
})
export class WikidataService {

  constructor(
    private _http: HttpClient
  ) { }

  getEntity(entityId: String) {
    let url = `https://www.wikidata.org/wiki/Special:EntityData/${entityId}.json`;

    return this._http.get(url).toPromise();
  }

  getEntityImage(entity: any, id:string) {
    let url;
    let image = op.get(entity.entities[id].claims.P18[0], 'mainsnak.datavalue.value');
    console.log(image)
    if (image) {
      let filename = image.split(" ").join("_")
      let hash = MD5(filename)
      url = `https://upload.wikimedia.org/wikipedia/commons/${hash[0]}/${hash[0]}${hash[1]}/${filename}`
    }
    return url;
  }
}
