<div class="clr-row">
    <div class="clr-col">
        <clr-toggle-container clrInline>
            <label>Filter</label>
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="sponsored" value="true" [(ngModel)]="sponsored" (change)="updateRoute()" />
                <label>Sponsored</label>
              </clr-toggle-wrapper>
              <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="verified" value="true" [(ngModel)]="verified" (change)="updateRoute()" />
                <label>Verified</label>
              </clr-toggle-wrapper>
              <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="noimage" value="true" [(ngModel)]="noimage" (change)="updateRoute()" />
                <label>No Image</label>
              </clr-toggle-wrapper>
              <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="wikidata" value="true" [(ngModel)]="wikidata" (change)="updateRoute()" />
                <label>Has WikiData</label>
              </clr-toggle-wrapper>
          </clr-toggle-container>
    </div>
</div>
<div *ngFor="let venue of venues" class="clr-row">
    <div class="clr-col">
        <a [routerLink]="[venue._id]"  class="card clickable">
            <div class="card-block">
                <div class="card-title">{{venue|name}}</div>
                <div class="card-text">
                    {{venue.address?.house_number}} {{venue.address?.road}}<span *ngIf="venue.address?.road">, </span>
                    {{venue.address?.suburb}}<span *ngIf="venue.address?.suburb">, </span>
                    {{venue.address?.city}}<span *ngIf="venue.address?.city">, </span>
                    {{venue.address?.county}}<span *ngIf="venue.address?.county">, </span>
                    {{venue.address?.postcode}}
                </div>
            </div>
        </a>
    </div>
</div>