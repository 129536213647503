import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ActivatedRoute } from '@angular/router';
import { WikidataService } from 'src/app/services/wikidata.service';
import * as op from 'object-path';

@Component({
  selector: 'app-venue-details',
  templateUrl: './venue-details.component.html',
  styleUrls: ['./venue-details.component.css']
})
export class VenueDetailsComponent implements OnInit {
  venue:any = {};
  wikidata:any;
  wikidataimg:string;

  newimg:any = {};

  goToLiveImg:boolean = true;

  verify_email:string;
  verify_status:'positive'|'negative'|'unknown' = "unknown";
  verify_submitted:boolean;


  constructor(
    private _data:DataService,
    private _wiki:WikidataService,
    private route : ActivatedRoute
  ) { }

  ngOnInit() {
    this.getVenue(this.route.snapshot.paramMap.get("id"))
  }

  saveAsLive(url:string, attr?:string, attr_url?:string) {
    this._data.saveOnlineImage(this.venue._id, url, attr, attr_url)
    .then(res => {
      console.log(res)
      this.getVenue(this.venue._id);
      this.goToLiveImg = true;
    })
  }

  getVenue(venueId:string) {
    this.getVenueEmail(venueId);
    this._data.getVenue(venueId)
    .then(
      (res:any) => {
        this.venue = res;

        //Set Verification status in form
        if(this.venue.verify) {
          this.verify_status = this.venue.verify.positive ? "positive" : "negative";
        } else {
          this.verify_status = "unknown";
        }

        //Get WikiData if an ID is set
        if(op.has(res, 'extratags.wikidata')) {
          this.getWD(res.extratags.wikidata)
        }
      }, 
      err => {
        console.error("getvenue", err)
      }
    )
  }

  getVenueEmail(venueId:string) {
    this._data.getVenueEmail(venueId)
    .then(
      res => {
        this.verify_email = res["email"];
      },
      err => {
        this.verify_email = ""
      }
    )
  }

  getWD(entityID:string) {
    this._wiki.getEntity(entityID)
    .then(
      res => {
        this.wikidata = res;
        this.wikidataimg = this._wiki.getEntityImage(res, entityID);
      }
    )
  }

  verifyVenue() {
    this._data.verifyVenue(this.venue._id, this.verify_email, this.verify_status)
    .then(
      res => {
        console.log("verifyVenue", res);
        this.getVenue(this.venue._id);
        this.verify_submitted = true;
      },
      err => {
        console.error("verifyVenue", err)
      }
    )
  }

}
