import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as objectPath from 'object-path';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit, OnChanges {
  verified:boolean;
  positive:boolean;
  sponsored:boolean;
  @Input() place:any;

  constructor() { }

  ngOnInit() {
    this.verified = objectPath.has(this.place, 'verify');
    this.positive = objectPath.get(this.place, 'verify.positive', false);    
  }

  ngOnChanges() {
    this.ngOnInit();
  }

}
