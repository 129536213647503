import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { VenueDetailsComponent } from './routes/venue-details/venue-details.component';
import { ClarityModule } from '@clr/angular';
import { LoginComponent } from './routes/login/login.component';
import { FormsModule } from '@angular/forms';
import { LayoutComponent } from './layout/layout.component';
import { VenueListComponent } from './routes/venue-list/venue-list.component';
import { NamePipe } from './pipes/name.pipe';
import { TypePipe } from './pipes/type.pipe';
import { StatusComponent } from './components/status/status.component';
import { OsmLinkPipe } from './pipes/osm-link.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    VenueDetailsComponent,
    LoginComponent,
    LayoutComponent,
    VenueListComponent,
    NamePipe,
    TypePipe,
    StatusComponent,
    OsmLinkPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ClarityModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
